import React, { useState, useEffect, useRef } from 'react'

import { useAppSelector } from 'redux/hooks'

import CardService from 'services/card.service'
import { CardListResponse } from 'services/apiTypes/card.types'

import { ScryfallSearchMeta } from 'types/searchV2'

import Input from 'components/formElements/SemanticInput'
import PhatButton from 'components/formElements/PhatButton'
import Checkbox from 'components/formElements/Checkbox'
import { appendSmartFilters } from 'components/formElements/CardAutocomplete'
import Icon from 'components/elements/Icon'

import { getBooleanFlagFromLocalStorage } from 'services/accountSettings.service'
import { stickyDeckSearchKey } from 'components/accountSettingsPage/BrowserSpecificSiteSettings'

import styles from './scryfallSearchForm.module.scss'

type Props = {
  onSubmitted: (search: string) => void
  onRequestFailed: () => void
  // send back the search object to the parent in case anything wants to be done with it
  onResponseRecieved?: (response: CardListResponse, query: ScryfallSearchMeta) => void
  className?: string
  containerClassName?: string
  searchString?: string
  smartFilters?: boolean
  // if we already have results, don't fetch on mount regardless of default search
  // the animation/ tab switching
  noAutoSearch?: boolean
  mainInputId?: string
  controlled?: boolean
  controlledTotal?: number

  hideTotal?: boolean
  hideInfoLink?: boolean

  placeholder?: string
  submitText?: string
}

const ScryfallSearchForm = ({
  onSubmitted,
  onRequestFailed,
  onResponseRecieved,
  searchString,
  smartFilters,
  className = '',
  containerClassName = '',
  noAutoSearch = false,
  mainInputId,
  controlled,
  controlledTotal,
  hideTotal = false,
  hideInfoLink = false,
  placeholder,
  submitText,
}: Props) => {
  let ref = useRef<any>(null)

  const isMobile = useAppSelector(state => state.active.isMobile)

  useEffect(() => {
    if (noAutoSearch) return
    if (searchString) return handleSearch()
    if (ref !== null && !isMobile) ref?.current.focus()
  }, [])

  const [search, setSearch] = useState<string>(searchString || '')
  const [total, setTotal] = useState<null | number>(null)
  const [localSmartFilters, setLocalSmartFilters] = useState(smartFilters || false)

  const handleSearch = (e?: React.SyntheticEvent) => {
    e?.preventDefault()

    onSubmitted(search) // let the parent know we're about to submit the search

    if (controlled) return

    // If the user has smart filters enabled, we need to append them to the search query. NOTE - don't return the appended string, only use it for searching
    const query = localSmartFilters ? appendSmartFilters({}, search)?.scryfallSmartSearch : search

    CardService.scryfallSearch(query, '', true, 1, true)
      .then(res => {
        setTotal(res.total)
        onResponseRecieved && onResponseRecieved(res, { query: search, smartFilters: localSmartFilters })
      })
      .catch(err => {
        console.error(err)

        onRequestFailed()
      })
  }

  useEffect(() => {
    if (searchString) setSearch(searchString)
  }, [searchString])

  return (
    <div
      className={`
        ${styles.container} 
        ${getBooleanFlagFromLocalStorage(stickyDeckSearchKey) ? styles.stickyForm : ''} 
        ${containerClassName}
      `}>
      <form onSubmit={e => handleSearch(e)} className={`${styles.form} ${className}`}>
        <Input
          input={{ id: mainInputId, ref: (semanticRef: any) => (ref.current = semanticRef) }}
          value={search}
          className={styles.input}
          onChange={setSearch}
          onFocus={() => ref.current.select()}
          placeholder={placeholder || 'color:red cmc:1'}
        />
        <PhatButton type="submit" color="green">
          <Icon name="search" />
          {submitText}
        </PhatButton>
      </form>
      <div className={styles.row}>
        <span className={styles.info}>
          {smartFilters !== undefined && (
            <Checkbox
              label={
                <label>
                  Apply smart filters <span className={styles.hideOnMobile}>(eg: format, game-type, color, etc)</span>
                </label>
              }
              checked={smartFilters}
              onChange={setLocalSmartFilters}
            />
          )}
        </span>

        {!hideInfoLink && (
          <a
            href="https://scryfall.com/docs/syntax"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.scryfallLink}>
            <Icon style={{ fontSize: '1.25rem' }} name="info circle" />
            Syntax guide
          </a>
        )}
      </div>

      {!hideTotal && (
        <div className={styles.row}>
          <span className={styles.info}>
            {(total !== null || controlledTotal) && `Total Results: ${controlledTotal || total}`}
          </span>
        </div>
      )}
    </div>
  )
}

export default ScryfallSearchForm
