import React, { useEffect, useRef, useState } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { isEqual } from 'lodash'

import config from 'environment'

import { CardListResponse, SearchOptions } from 'services/apiTypes/card.types'
import { ArchidektSearchMeta, generateArchidektSearchState, setArchidektSearchOrderBy } from 'types/searchV2'

import CardService from 'services/card.service'
import { getBooleanFlagFromLocalStorage } from 'services/accountSettings.service'
import { stickyDeckSearchKey } from 'components/accountSettingsPage/BrowserSpecificSiteSettings'

import { useAppSelector } from 'redux/hooks'

import SetDropdown from 'components/cardSearchPanel/searchFormElements/setDropdown'
import SubTypeDropdown from 'components/cardSearchPanel/searchFormElements/subTypeDropdown'
import Input from 'components/formElements/SemanticInput'
import RarityToggles from 'components/cardSearchPanel/searchFormElements/rarityToggles'
import Accordion from 'components/elements/Accordion'
import SuperTypeDropdown from 'components/cardSearchPanel/searchFormElements/superTypeDropdown'
import TypeDropdown from 'components/cardSearchPanel/searchFormElements/typeDropdown'
import FormatDropdown from 'components/cardSearchPanel/searchFormElements/formatDropdown'
import GreatherLessEqualToInput from 'components/cardSearchPanel/searchFormElements/numericElements'
import ColorToggles, {
  ColorAndOrRadio,
  ColorIdentityRadio,
} from 'components/cardSearchPanel/searchFormElements/colorToggles'
import CardAutocomplete from 'components/formElements/CardAutocomplete'
import SortingDropdown from './sortingDropdown'
import SortDirectionDropdown from './sortDirectionDropdown'
import GameOptionDropdown from './gameOptionDropdown'
import CollectionFilterDropdown from './collectionFilterDropdown'
import AllPrintingsDropdown from './allPrintingsDropdown'
import UniverseBeyondCheckbox from './universeBeyondCheckbox'
import PhatButton from 'components/formElements/PhatButton'

import styles from './archidektSearchForm.module.scss'

const NO_REST_FIELDS: Partial<keyof SearchOptions>[] = ['name']

// Returns true if anything but the name has been changed
export const checkIsModified = (searchOptions: SearchOptions) => {
  const refercene = generateArchidektSearchState()

  const alteredFields = Object.keys(refercene).filter(key => {
    if (NO_REST_FIELDS.includes(key as keyof SearchOptions)) return false

    const defaultState = refercene[key as keyof SearchOptions]
    const currentState = searchOptions[key as keyof SearchOptions]

    if (isEqual(defaultState, currentState)) return false

    return true
  })

  return alteredFields.length > 0
}

type Props = {
  onSubmitted: (query: ArchidektSearchMeta) => void
  onRequestFailed: () => void

  // send back the search object to the parent in case anything wants to be done with it
  onResponseRecieved?: (response: CardListResponse, query: ArchidektSearchMeta) => void
  className?: string
  containerClassName?: string
  searchOptions?: SearchOptions

  // if we already have results, don't fetch on mount regardless of default search
  // the animation/ tab switching
  noAutoSearch?: boolean
  mainInputId?: string

  controlled?: boolean
  controlledTotal?: number
  hideTotal?: boolean
  hideSort?: boolean

  placeholder?: string
  submitText?: string
}

const ArchidektSearchForm = ({
  onSubmitted,
  onRequestFailed,
  onResponseRecieved,
  className = '',
  containerClassName = '',
  searchOptions = {},
  noAutoSearch = false,
  mainInputId,
  controlled,
  controlledTotal,
  hideTotal,
  hideSort,
  placeholder,
  submitText,
}: Props) => {
  let ref = useRef<any>(null)

  const isMobile = useAppSelector(state => state.active.isMobile)

  const [search, setSearch] = useState<SearchOptions>(generateArchidektSearchState(searchOptions))
  const [totalResults, setTotalResults] = useState<number | null>(null)
  const [advancedOpen, setAdvancedOpen] = useState(false)
  const [filterSortOpen, setFilterSortOpen] = useState(false)

  const handleSetSearch = (partialSearch: Partial<SearchOptions>) => setSearch({ ...search, ...partialSearch })

  const handleSearch = (e: React.SyntheticEvent | undefined, immediateSearch = {} as Partial<SearchOptions>) => {
    e?.preventDefault()

    onSubmitted({ ...search, ...immediateSearch }) // let the parent know we're about to submit the search

    setAdvancedOpen(false)
    setFilterSortOpen(false)
    setArchidektSearchOrderBy(search.orderBy || 'oracleCard__name')

    ref.current.blur()

    if (controlled) return

    CardService.list({ ...search, ...immediateSearch }, '', true)
      .then(res => {
        onResponseRecieved && onResponseRecieved(res, { ...search, ...immediateSearch })
        setTotalResults(res.total)
      })
      .catch(err => {
        console.error(err)

        onRequestFailed()
      })
  }

  const handleReset = () => {
    const cleanState = generateArchidektSearchState()

    for (const key of NO_REST_FIELDS) {
      // @ts-ignore
      cleanState[key] = search[key]
    }

    setSearch({ ...cleanState })
  }

  useEffect(() => {
    if (noAutoSearch) return
    if (checkIsModified(searchOptions) || searchOptions.name) return handleSearch(undefined)
    if (ref !== null && !isMobile) ref?.current.focus()
  }, [])

  useEffect(() => {
    setSearch(generateArchidektSearchState(searchOptions))
  }, [searchOptions])

  // prettier-ignore
  const wrappingClasses = (getBooleanFlagFromLocalStorage(stickyDeckSearchKey) ? styles.stickyForm : `${styles.form}`) + ` ${containerClassName}`

  return (
    <div className={wrappingClasses}>
      <form onSubmit={e => handleSearch(e)} className={`${styles.form} ${className}`}>
        <div className={styles.searchInputRow}>
          <CardAutocomplete
            // Timeout provided so as to allow the component to initalize
            id={mainInputId}
            ref={ref}
            value={`${search.name || ''}`}
            onFocus={() => ref.current.select()}
            onChange={(_e: any, { value }: any) => handleSetSearch({ name: value })}
            onSelect={(name: string) => {
              handleSetSearch({ name })
              handleSearch(undefined, { name })
            }}
            icon={null}
            placeholder={placeholder || `Card Search (${config.getOnMac() ? 'Cmd' : 'Ctrl'} + Enter Opens Search)`}
            className={styles.autocomplete}
          />
          <PhatButton color="green" type="submit">
            <Icon name="search" />
            {submitText}
          </PhatButton>
        </div>

        <div className={styles.accordionContainer}>
          <Button
            className="clear"
            basic
            disabled={!checkIsModified(search)}
            color="orange"
            type="button"
            onClick={handleReset}>
            Clear
          </Button>
          <Accordion value={advancedOpen} onChange={setAdvancedOpen} label="Advanced Options">
            <div className={styles.row}>
              <SetDropdown
                single={false}
                value={search.editions}
                onChange={editions => handleSetSearch({ editions })}
              />
              <FormatDropdown
                value={search.formatLegality}
                onChange={formatLegality => handleSetSearch({ formatLegality })}
              />
            </div>
            <div className={styles.row}>
              <ColorAndOrRadio value={search.andcolors} onChange={andcolors => handleSetSearch({ andcolors })} />
              <ColorToggles value={search.colors} onChange={colors => handleSetSearch({ colors })} />
              <ColorIdentityRadio
                value={search.colorIdentity}
                onChange={colorIdentity => handleSetSearch({ colorIdentity })}
              />
            </div>
            <div className={styles.row}>
              <RarityToggles value={search.rarity} onChange={rarity => handleSetSearch({ rarity })} />

              <UniverseBeyondCheckbox
                value={search.hideUniverseBeyond}
                onChange={hideUniverseBeyond => handleSetSearch({ hideUniverseBeyond })}
              />
            </div>
            <div className={styles.row}>
              <TypeDropdown value={search.types} onChange={types => handleSetSearch({ types })} />
            </div>
            <div className={styles.row}>
              <SuperTypeDropdown value={search.superTypes} onChange={superTypes => handleSetSearch({ superTypes })} />
              <SubTypeDropdown value={search.subTypes} onChange={subTypes => handleSetSearch({ subTypes })} />
            </div>
            <div className={styles.row}>
              <Input
                placeholder="Oracle Text"
                value={search.oracleText}
                onChange={oracleText => handleSetSearch({ oracleText })}
              />
            </div>
            <div className={styles.row}>
              <Input
                placeholder="Mana Cost: (eg: {W}{U}{1})"
                value={search.manaCost}
                onChange={manaCost => handleSetSearch({ manaCost })}
              />
            </div>
            <div className={styles.row}>
              <GreatherLessEqualToInput
                value={search.cmc}
                operations={[search.gtecmc, search.ltecmc]}
                id="MV"
                placeholder="Mana value"
                onValueChange={cmc => handleSetSearch({ cmc })}
                onOperationChange={([gtecmc, ltecmc]) => handleSetSearch({ gtecmc, ltecmc })}
              />
            </div>
            <div className={styles.row}>
              <GreatherLessEqualToInput
                value={search.power}
                operations={[search.gtepower, search.ltepower]}
                id="Power"
                placeholder="Power"
                onValueChange={power => handleSetSearch({ power })}
                onOperationChange={([gtepower, ltepower]) => handleSetSearch({ gtepower, ltepower })}
              />
            </div>
            <div className={styles.row}>
              <GreatherLessEqualToInput
                value={search.toughness}
                operations={[search.gtetoughness, search.ltetoughness]}
                id="Toughness"
                placeholder="Toughness"
                onValueChange={toughness => handleSetSearch({ toughness })}
                onOperationChange={([gtetoughness, ltetoughness]) => handleSetSearch({ gtetoughness, ltetoughness })}
              />
            </div>

            <div className={styles.row}>
              <Input placeholder="Flavor Text" value={search.flavor} onChange={flavor => handleSetSearch({ flavor })} />
              <Input placeholder="Artist" value={search.artist} onChange={artist => handleSetSearch({ artist })} />
            </div>
            <div className={styles.row}>
              <Input
                placeholder="Collector Number"
                value={search.collectorNumber}
                onChange={collectorNumber => handleSetSearch({ collectorNumber })}
              />
              <Input placeholder="Lore" value={search.lore} onChange={lore => handleSetSearch({ lore })} />
            </div>
          </Accordion>
        </div>

        {!hideSort && (
          <Accordion value={filterSortOpen} onChange={setFilterSortOpen} label="Filter & Sort">
            <SortDirectionDropdown value={search.descending} onChange={descending => handleSetSearch({ descending })} />
            <SortingDropdown value={search.orderBy} onChange={orderBy => handleSetSearch({ orderBy })} />
            <GameOptionDropdown value={search.game} onChange={game => handleSetSearch({ game })} />
            <CollectionFilterDropdown
              value={search.collection}
              onChange={collection => handleSetSearch({ collection })}
            />
            <AllPrintingsDropdown
              value={search.allEditions}
              onChange={allEditions => handleSetSearch({ allEditions })}
            />
          </Accordion>
        )}

        {!hideTotal && totalResults !== null && (
          <div className={`${styles.row} ${styles.info}`}>
            <span>Total Results: {controlledTotal || totalResults}</span>
          </div>
        )}
      </form>
    </div>
  )
}

export default ArchidektSearchForm
